import React from 'react'
import { useInterval } from '../../hooks/useInterval'
import httpService from '../../services/httpService'
import { logInfo, LOGS_KEY } from '../../services/loggerService'
import { getAccessToken, getCIUrls } from '../../utils/auth.utils'
import { isBrowser } from '../../utils/general.utils'
import { getBffBaseUrl } from '../../services/configService'

export const fiveMinutes = 300000

const LogSender: React.FC = ({ children }): any => {
  const { idBrokerUrl } = getCIUrls()

  useInterval(async () => {
    if (isBrowser()) {
      const storedLogs = localStorage.getItem(LOGS_KEY)
      const token = await getAccessToken()

      if (storedLogs && token) {
        try {
          const parsedLogs = JSON.parse(storedLogs)

          const httpConfig = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }

          await httpService.post(
            `${getBffBaseUrl()}/logs`,
            { logs: parsedLogs, idBrokerHost: idBrokerUrl },
            httpConfig
          )
        } catch (err) {
          logInfo('Error sending FE logs')
        } finally {
          localStorage.setItem(LOGS_KEY, '')
        }
      }
    }
  }, fiveMinutes)

  return children
}

export default LogSender
