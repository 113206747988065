import React from 'react'
import { useTranslation } from 'react-i18next'
import CiscoLogoIcon from '../../assets/svgs/cisco-logo-white.svg'

const Footer: React.FC = () => {
  const { t } = useTranslation()

  return (
    <footer className="footer">
      <div className="content">
        <CiscoLogoIcon />
        <div>
          © {`${new Date().getFullYear()} ${t('footer.copyRightText')}`}
        </div>
      </div>
    </footer>
  )
}

export default Footer
