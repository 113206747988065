import { Reducer } from 'redux'
import { User } from '../../interfaces/ciInterfaces'
import { IApi } from '../../interfaces/contentInterface'
import { IFeatureFlagResponse } from '../../interfaces/featureFlagInterfaces'
import { IGrpc } from '../../interfaces/grpcInterfaces'
import { IResource } from '../../interfaces/resourceInterfaces'
import { IRest } from '../../interfaces/restInterfaces'
import { IWebhook } from '../../interfaces/webhookInterfaces'
import { setConfigUrls } from '../../services/configService'
import httpService from '../../services/httpService'
import {
  getCIUrls,
  getUser,
  shouldWeBootstrapAuthenticatedApp,
} from '../../utils/auth.utils'
import { AppState, AppThunk } from '../store'
import { getBffBaseUrl } from '../../services/configService'
export interface AuthStateInterface {
  bootstrappingAuthenticatedApp: boolean
  featureFlags: IFeatureFlagResponse
  user: User | {}
  token: string
  permittedData: any
  hasAuthError: boolean
}

export enum AuthActionTypes {
  BOOTSTRAP_COMPLETE = 'BOOTSTRAP_COMPLETE',
  FETCHED_FEATURE_FLAGS_SUCCESS = 'FETCHED_FEATURE_FLAGS_SUCCESS',
  FETCHED_FEATURE_FLAGS_FAILURE = 'FETCHED_FEATURE_FLAGS_FAILURE',
  SET_USER_SUCCESS = 'SET_USER_SUCCESS',
  SET_USER_FAILURE = 'SET_USER_FAILURE',
  SET_PERMITTED_DATA = 'SET_PERMITTED_DATA',
  SET_AUTH_FAILURE = 'SET_AUTH_FAILURE',
  SET_TOKEN = 'SET_TOKEN',
}
export interface IChildNavItem {
  name: string
  link: string
}

export const loginUserAction = (
  token: string,
  flags: string[],
  strapiData: any
): AppThunk => (dispatch: any): any => {
  return dispatch(setUserAction(token))
    .then((result: any) => {
      if (result.type === AuthActionTypes.SET_USER_SUCCESS && flags.length) {
        return dispatch(fetchFeatureFlags(flags, token))
      }
    })
    .then((result: any) => {
      if (result.type === AuthActionTypes.FETCHED_FEATURE_FLAGS_SUCCESS) {
        return dispatch(setPermittedData(strapiData, result.payload))
      }
    })
}

export const setTokenAction = (token: string): any => ({
  type: AuthActionTypes.SET_TOKEN,
  payload: token,
})

export const setUserAction = (token: string): AppThunk => (dispatch): any =>
  getUser(token).then(
    (user) => {
      setConfigUrls(user.dataCenter)
      return dispatch({
        type: AuthActionTypes.SET_USER_SUCCESS,
        payload: { ...user },
      })
    },
    (err) => {
      dispatch({
        type: AuthActionTypes.SET_USER_FAILURE,
      })
      return Promise.reject('Failed to get user')
    }
  )

export const fetchFeatureFlags = (flags: string[], token: string): AppThunk => (
  dispatch
): any => {
  const { idBrokerUrl } = getCIUrls()
  const httpConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      featureFlags: flags.join(','),
      idBrokerHost: idBrokerUrl,
    },
  }
  const url = `${getBffBaseUrl()}/featureFlags`
  return httpService.get(url, httpConfig).then(
    (flagsResult) =>
      dispatch({
        type: AuthActionTypes.FETCHED_FEATURE_FLAGS_SUCCESS,
        payload: flagsResult.data,
      }),
    (err) => dispatch({ type: AuthActionTypes.FETCHED_FEATURE_FLAGS_FAILURE })
  )
}

export const setPermittedData = (
  strapiData: any,
  featureFlagsResult: IFeatureFlagResponse
): AppThunk => (dispatch): any => {
  const isItemPermitted = (item: IApi): boolean =>
    item.featureFlag === null ||
    featureFlagsResult[item.featureFlag.name] === 'on'

  const filterOutHiddenItems = (items: IApi[]): any[] =>
    items.filter(isItemPermitted)

  const displayableRests = filterOutHiddenItems(
    strapiData.allStrapiRestapis.nodes
  )

  const displayableGrpcs = filterOutHiddenItems(strapiData.allStrapiGrpcs.nodes)

  const displayableWebhooks = filterOutHiddenItems(
    strapiData.allStrapiWebhooks.nodes
  )

  const displayableGuides = filterOutHiddenItems(
    strapiData.allStrapiGuides.nodes.filter((guide: any) => {
      return guide.section === 'guides'
    })
  )
  const displayableFeaturePages = filterOutHiddenItems(
    strapiData.allStrapiFeaturePages.nodes
  )

  const displayablePartnerShowcases = filterOutHiddenItems(
    strapiData.allStrapiPartnerShowCases.nodes
  )

  const displayableOverviewGuides = filterOutHiddenItems(
    strapiData.allStrapiGuides.nodes.filter((guide: any) => {
      return guide.section === 'overview'
    })
  )

  const displayableGraphqls = filterOutHiddenItems(
    strapiData.allStrapiGraphqls.nodes
  )

  const displayableResources = strapiData.allStrapiResources.nodes
    .map((node: IResource) => ({
      ...node,
      restapis: node.restapis.filter((rest: IRest) => {
        return displayableRests.find(
          ({ strapiId }) => strapiId === Number(rest.id)
        )
      }),
      grpcs: node.grpcs.filter((grpc: IGrpc) =>
        displayableGrpcs.find(({ strapiId }) => strapiId === Number(grpc.id))
      ),
      webhooks: node.webhooks.filter((webhook: IWebhook) =>
        displayableWebhooks.find(
          ({ strapiId }) => strapiId === Number(webhook.id)
        )
      ),
      graphqls: node.graphqls.filter((graphql: IRest) =>
        displayableGraphqls.find(
          ({ strapiId }) => strapiId === Number(graphql.id)
        )
      ),
    }))
    .filter(
      (node: IResource) =>
        node.restapis.length > 0 ||
        node.grpcs.length > 0 ||
        node.webhooks.length > 0 ||
        node.graphqls.length > 0
    )

  return dispatch({
    type: AuthActionTypes.SET_PERMITTED_DATA,
    payload: {
      resources: displayableResources,
      restapis: displayableRests,
      grpcs: displayableGrpcs,
      guides: displayableGuides,
      webhooks: displayableWebhooks,
      overview: displayableOverviewGuides,
      graphqls: displayableGraphqls,
      partnerShowcase: displayablePartnerShowcases,
      featurePages: displayableFeaturePages,
    },
  })
}

export const completeBootstrap = (): any => {
  return {
    type: AuthActionTypes.BOOTSTRAP_COMPLETE,
  }
}

export const setAuthFailure = (): any => {
  return {
    type: AuthActionTypes.SET_AUTH_FAILURE,
  }
}

const initialState: AuthStateInterface = {
  bootstrappingAuthenticatedApp: shouldWeBootstrapAuthenticatedApp(),
  featureFlags: {},
  user: {},
  token: '',
  permittedData: {},
  hasAuthError: false,
}

export const authReducer: Reducer<AuthStateInterface> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AuthActionTypes.SET_USER_FAILURE: {
      return { ...state, user: {} }
    }
    case AuthActionTypes.SET_USER_SUCCESS: {
      return { ...state, user: action.payload }
    }
    case AuthActionTypes.FETCHED_FEATURE_FLAGS_SUCCESS: {
      return { ...state, featureFlags: action.payload }
    }
    case AuthActionTypes.FETCHED_FEATURE_FLAGS_FAILURE: {
      return { ...state, featureFlags: {} }
    }
    case AuthActionTypes.BOOTSTRAP_COMPLETE: {
      return { ...state, bootstrappingAuthenticatedApp: false }
    }
    case AuthActionTypes.SET_PERMITTED_DATA: {
      return { ...state, permittedData: action.payload }
    }
    case AuthActionTypes.SET_AUTH_FAILURE: {
      return { ...state, hasAuthError: true }
    }
    case AuthActionTypes.SET_TOKEN: {
      return { ...state, token: action.payload }
    }
    default: {
      return state
    }
  }
}

export const retrieveUser = (state: AppState): User | {} => state.auth.user

export const retrieveToken = (state: AppState): string => state.auth.token

export const retrieveBootstrappingState = (state: AppState): boolean =>
  state.auth.bootstrappingAuthenticatedApp

export const retrievePermittedData = (state: AppState): any =>
  state.auth.permittedData

export const retrieveFeatureFlags = (state: AppState): IFeatureFlagResponse =>
  state.auth.featureFlags

export const retrieveAuthFailureState = (state: AppState): boolean =>
  state.auth.hasAuthError
