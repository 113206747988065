import { AlertBanner, Button } from '@momentum-ui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import MetricsService from '../../services/metricsService'
import { retrieveFeatureFlags } from '../../state/auth'
import { AppState } from '../../state/store'

const CookieNotification: React.FC = () => {
  const { t } = useTranslation()
  const userFeatureFlags = useSelector((state: AppState) =>
    retrieveFeatureFlags(state)
  )

  const metricsService = MetricsService.getInstance(userFeatureFlags)

  const [showMixpanelNotifcation, setShowMixpanelNotification] = useState(
    !metricsService.hasUserOptedInOrOut()
  )

  const handleClose = (acceptPolicy: boolean): void => {
    setShowMixpanelNotification(false)
    acceptPolicy
      ? metricsService.optUserIntoTracking()
      : metricsService.optUserOutOfTracking()
  }

  return (
    <>
      {metricsService.getAreMetricsEnabled() ? (
        <AlertBanner
          show={showMixpanelNotifcation}
          closable
          onHide={(): void => handleClose(false)}
          className="cookie-notification"
        >
          {t('cookieNotification.policy')}

          <Button
            onClick={(): void => {
              handleClose(true)
            }}
            className="notification-btn-default"
            size={28}
          >
            {t('cookieNotification.accept')}
          </Button>
          <Button
            onClick={(): void => {
              handleClose(false)
            }}
            className="notification-btn-default"
            size={28}
          >
            {t('cookieNotification.reject')}
          </Button>
        </AlertBanner>
      ) : null}
    </>
  )
}

export default CookieNotification
