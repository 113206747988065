import React from 'react'
import { useTranslation } from 'react-i18next'
import InternalServerErrorIcon from '../../assets/svgs/service-unavailable.svg'
import ErrorTemplate from '../../templates/errors'

interface IInternalServerErrorProps {
  reset?: Function
}

const InternalServerError: React.FC<IInternalServerErrorProps> = ({
  reset,
}) => {
  const { t } = useTranslation()

  return (
    <ErrorTemplate
      title={t('errorPage.title')}
      icon={<InternalServerErrorIcon />}
      resetError={reset}
    >
      <>
        {t('errorPage.bodyIntro')}
        <br />
        {t('errorPage.bodyConclusion')}
      </>
    </ErrorTemplate>
  )
}

export default InternalServerError
