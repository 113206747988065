import React, { Component, ErrorInfo } from 'react'
import { logError } from '../../services/loggerService'
import { isBrowser } from '../../utils/general.utils'
import InternalServerError from '../InternalServerError'

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  }

  constructor(props: any) {
    super(props)
    this.reset = this.reset.bind(this)
  }
  static getDerivedStateFromError(error: string): { [key: string]: boolean } {
    return { hasError: true }
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    logError(JSON.stringify({ error, errorInfo }))
  }
  componentDidMount(): void {
    if (isBrowser()) {
      window.onerror = (message, source, lineno, colno, error): void => {
        logError(
          `Unhandled exception: ${message} ${source} ${lineno} ${colno} ${error}`
        )
      }
    }
  }

  reset(): void {
    this.setState({ hasError: false })
  }
  render(): React.ReactNode {
    const { hasError } = this.state
    if (hasError) {
      return <InternalServerError reset={this.reset} />
    }
    return this.props.children
  }
}

export default ErrorBoundary
