import React from 'react'
import CookieNotification from '../components/CookieNotification'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { isBrowser } from '../utils/general.utils'

const BasicLayout: React.FC = ({ children }) => {
  return (
    <>
      <Header />
      {isBrowser() ? <CookieNotification /> : null}
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default BasicLayout
