import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface IPageContenxtProviderProps {
  children: React.ReactNode
  value: any
}

const PageContext = React.createContext<{ [key: string]: any }>({})

export const PageContextProvider: React.FC<IPageContenxtProviderProps> = ({
  value,
  children,
}): JSX.Element => {
  const { i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage(value.lang)
  }, [i18n, value.lang])

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}

export const usePageContext = (): { [key: string]: any } =>
  React.useContext(PageContext)
