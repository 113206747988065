import {
  Accordion,
  AccordionContent,
  AccordionGroup,
  AccordionHeader,
  ListItem,
} from '@momentum-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IChildNavItem, useNavData } from '../../../hooks/useNavData'
import Link from '../../Link'

interface MobileSideNavProps {
  handleMobileSideNavClick: Function
}

const MobileSideNav: React.FC<MobileSideNavProps> = ({
  handleMobileSideNavClick,
}) => {
  const navData = useNavData()
  const { t } = useTranslation()

  const createNavLink = (
    item: IChildNavItem,
    index: number
  ): React.ReactNode => {
    return (
      <ListItem key={index}>
        <Link
          to={item.link}
          className="link"
          activeClassName="selected-link"
          partiallyActive={true}
          onClick={(): void => handleMobileSideNavClick(false)}
        >
          <>{item.name}</>
        </Link>
      </ListItem>
    )
  }

  return (
    <Accordion multipleVisible className="mobile-side-nav">
      <AccordionGroup>
        <AccordionHeader>{t('header.documentation')}</AccordionHeader>
        <AccordionContent>
          {navData.map((section, i) => {
            return (
              <section key={i}>
                <h5 className="nav-section-header">{section.name}</h5>
                {section.children.map(createNavLink)}
              </section>
            )
          })}
        </AccordionContent>
      </AccordionGroup>
    </Accordion>
  )
}

export default MobileSideNav
