import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { IFeatureFlagResponse } from '../interfaces/featureFlagInterfaces'

export const isBrowser = (): boolean => typeof window !== 'undefined'

export const makeKebabCase = (str: string): string => {
  return str?.toLowerCase().split(' ').join('-')
}

export const makeSnakeCase = (str: string): string => {
  return str?.toLowerCase().split(' ').join('_')
}

export const throttle = (callback: Function, wait: number): (() => void) => {
  let isWaiting = false

  return (): void => {
    if (!isWaiting) {
      callback()
      isWaiting = true
      setTimeout(function () {
        isWaiting = false
      }, wait)
    }
  }
}

export const makeArrayTypeString = (
  typeOrFormat: string | undefined
): string | undefined => {
  return typeOrFormat ? `${typeOrFormat}[]` : undefined
}

export const dateFormatter = (time: number): string => {
  return dayjs(time).format('MMMM D, YYYY')
}

export const objIsEmpty = (obj: { [key: string]: any }): boolean => {
  return Object.keys(obj).length === 0
}

export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min) //The maximum is exclusive and the minimum is inclusive
}

export const hasValidFeatureFlag = (
  userFeatureFlags: IFeatureFlagResponse | undefined,
  needsFeatureFlag: string,
  featureFlagName: string
): boolean => {
  if (userFeatureFlags) {
    if (needsFeatureFlag === 'true') {
      return Object.entries(userFeatureFlags).some(
        (featureFlag) =>
          featureFlag[0] === featureFlagName && featureFlag[1] === 'on'
      )
    }
    return true
  } else {
    return false
  }
}

export const removeSpecialCharacters = (str?: string): string | undefined => {
  return str?.replace(/[^\w\s]/gi, ' ')
}

export const escapeSpecialCharacters = (str: string): string => {
  return str.replace(/([ !"$%&'()*+,.\/:;<=>?@[\]^`{|}~])/g, '\\$1')
}

export const cleanApiName = (str: string): string => {
  return str
    .replace(/\(.*?\)|\./g, '')
    .replace(/([a-z])([A-Z])| +/g, '$1 $2')
    .trim()
}

export const createHttpConfig = (
  token: string,
  params: { [key: string]: string }
): { [key: string]: any } => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  }
}

export const generateTrackingId = (): string => {
  const TRACKING_ID_SENDER_TYPE = 'DP'
  return `${TRACKING_ID_SENDER_TYPE}_${uuidv4()}`
}
