import i18n from 'i18next'
import enTraslationJson from '../locales/en/translation.json'

i18n.init({
  fallbackLng: 'en',
  resources: {
    en: {
      translation: enTraslationJson,
    },
  },
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: process.env.NODE_ENV === 'development' ? true : false,
  },
})

i18n.languages = ['en']

export default i18n
