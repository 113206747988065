import { Button, Icon } from '@momentum-ui/react'
import { navigate } from 'gatsby'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import WebexWordMark from '../../../assets/svgs/webex-ce-logo.svg'
import { login, logout } from '../../../utils/auth.utils'
import Link from '../../Link'
import MobileSideNav from './../MobileSideNav'

interface IMobileMenuProps {
  handleMobileSideNavClick: Function
  isUserLoggedIn: boolean
}

const MobileMenu: React.FC<IMobileMenuProps> = ({
  handleMobileSideNavClick,
  isUserLoggedIn,
}) => {
  const { t } = useTranslation()
  const mobileMenuRef: React.Ref<HTMLDivElement> = useRef(null)

  useEffect(() => {
    const handleClickOutsideMenu = (event: any): void => {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target)
      ) {
        handleMobileSideNavClick(false)
      }
    }

    document.addEventListener('click', handleClickOutsideMenu)

    return (): void => {
      document.removeEventListener('click', handleClickOutsideMenu)
    }
  }, [mobileMenuRef, handleMobileSideNavClick])

  const loginButton = (): JSX.Element => {
    return (
      <Button className="md-list-item login-button-mobile" onClick={login}>
        {t('header.login')}
      </Button>
    )
  }

  const loggedInButtons = (): JSX.Element => {
    return (
      <>
        <Button
          className="md-list-item no-border login-button-mobile"
          onClick={(): Promise<void> => navigate('/my-apps')}
        >
          {t('header.myApps')}
        </Button>
        <Button
          className="md-list-item no-border login-button-mobile"
          onClick={logout}
        >
          {t('header.logout')}
        </Button>
      </>
    )
  }

  const renderLinks = (): JSX.Element => {
    return isUserLoggedIn ? loggedInButtons() : loginButton()
  }

  return (
    <div
      id="mobileMenu"
      ref={mobileMenuRef}
      className="md-top-bar__mobile md-tb-mobile open"
    >
      <Button
        className="md-button md-button--36 md-button--icon md-tb-mobile__close"
        onClick={(): void => {
          handleMobileSideNavClick(false)
        }}
        ariaLabel="Close Mobile Menu"
      >
        <Icon name="icon-cancel_20"></Icon>
      </Button>
      <div className="md-top-bar__brand">
        <Link className="md-brand active center-align" to="/">
          <WebexWordMark alt={t('header.webexContactCenter')} />
        </Link>
      </div>
      <div className="md-list-separator"></div>
      <nav data-cy="mobileNav" className="md-tb-mobile__nav" role="navigation">
        <MobileSideNav handleMobileSideNavClick={handleMobileSideNavClick} />
        <Link className="md-list-item no-border" to="/support">
          {t('support.supportHeader')}
        </Link>
        <div className="md-list-separator"></div>
        {renderLinks()}
      </nav>
    </div>
  )
}

export default MobileMenu
