import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import ErrorBoundary from './components/ErrorBoundary'
import LogSender from './components/LogSender'
import i18n from './i18n'
import createStore from './state/store'

// eslint-disable-next-line react/display-name
export default ({ element }: any): JSX.Element => {
  // This function is exported and imported as 'wrapRootElement' in Gatsby's SSR and browser rendering process
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore()
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <LogSender>
          <ErrorBoundary>{element}</ErrorBoundary>
        </LogSender>
      </I18nextProvider>
    </Provider>
  )
}
