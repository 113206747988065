import React, { useCallback, useEffect } from 'react'
import Link from '../../components/Link'
import BasicLayout from '../../layouts/BasicLayout'

interface IErrorTemplateProps {
  title: React.ReactNode
  icon: React.ReactNode
  children: React.ReactNode
  resetError?: Function
}

const ErrorTemplate: React.FC<IErrorTemplateProps> = ({
  title,
  icon,
  children,
  resetError,
}) => {
  const handleClick = useCallback((): void => {
    if (resetError) {
      resetError()
    }
  }, [resetError])

  useEffect(() => {
    const links = Array.from(document.querySelectorAll('a')).filter(
      (link) => !link.classList.contains('error-template-links')
    )

    links?.forEach((link) => {
      link.addEventListener('click', handleClick)
    })

    return (): void => {
      links?.forEach((link) => {
        link.removeEventListener('click', handleClick)
      })
    }
  }, [handleClick, resetError])

  return (
    <BasicLayout>
      <section className="error-page">
        <div>
          {icon}
          <h2>{title}</h2>
          <div className="text">{children}</div>
          <div className="link-container">
            <div>
              <Link
                to="/"
                onClick={handleClick}
                className="error-template-links"
              >
                Home
              </Link>
            </div>
            <div className="second-link">
              <Link
                to="/documentation/getting-started"
                onClick={handleClick}
                className="error-template-links"
              >
                Documentation
              </Link>
            </div>
          </div>
        </div>
      </section>
    </BasicLayout>
  )
}

export default ErrorTemplate
