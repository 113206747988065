import { Alert, AlertContainer } from '@momentum-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IErrorAlertProps {
  title?: string
  showAlert: boolean
  handleClick: Function
}

const ErrorAlert: React.FC<IErrorAlertProps> = ({
  title,
  showAlert,
  handleClick,
}) => {
  const { t } = useTranslation()

  return (
    <AlertContainer>
      <Alert
        className={'error-alert-modal'}
        type={'error'}
        title={title || t('errorAlert.title')}
        message={t('errorAlert.message')}
        show={showAlert}
        dismissBtnProps={{
          onClick: (): void => handleClick(false),
          ariaLabel: t('home.closeAlertAria'),
        }}
        data-cy="error-alert-modal"
      />
    </AlertContainer>
  )
}

export default ErrorAlert
