let bffUrl = `${process.env.GATSBY_BFF_BASE_URL}`
let gtwyUrl = `${process.env.GATEWAY_URL}`

export const setConfigUrls = (dataCenter?: string): void => {
  const bffMap: { [key: string]: string } = {
    US1: 'https://devportal-bff.produs1.ciscoccservice.com',
    EU1: 'https://devportal-bff.prodeu1.ciscoccservice.com',
    EU2: 'https://devportal-bff.prodeu2.ciscoccservice.com',
    ANZ1: 'https://devportal-bff.prodanz1.ciscoccservice.com',
    CA1: 'https://devportal-bff.prodca1.ciscoccservice.com',
    JP1: 'https://devportal-bff.prodjp1.ciscoccservice.com',
    SG1: 'https://devportal-bff.prodsg1.ciscoccservice.com',
    INTG: 'https://devportal-bff.intgus1.ciscoccservice.com',
    LOAD: 'https://devportal-bff.loadus1.ciscoccservice.com',
    QA: 'https://devportal-bff.qaus1.ciscoccservice.com',
  }

  const gtwyMap: { [key: string]: string } = {
    US1: 'https://api.wxcc-us1.cisco.com',
    EU1: 'https://api.wxcc-eu1.cisco.com',
    EU2: 'https://api.wxcc-eu2.cisco.com',
    ANZ1: 'https://api.wxcc-anz1.cisco.com',
    CA1: 'https://api.wxcc-ca1.cisco.com',
    JP1: 'https://api.wxcc-jp1.cisco.com',
    SG1: 'https://api.wxcc-sg1.cisco.com',
    INTG: 'https://api.intgus1.ciscoccservice.com',
    LOAD: 'https://api.loadus1.ciscoccservice.com',
    QA: 'https://api.qaus1.ciscoccservice.com',
  }

  if (dataCenter) {
    bffUrl = bffMap[dataCenter] || `${process.env.GATSBY_BFF_BASE_URL}`
    gtwyUrl = gtwyMap[dataCenter] || `${process.env.GATEWAY_URL}`
  }
}

export const getGtwyUrl = (): string => {
  return gtwyUrl
}

export const getBffBaseUrl = (): string => {
  return bffUrl
}
