import { Link as GatsbyLink } from 'gatsby'
import React from 'react'
import { usePageContext } from '../../i18ncontext'
interface ILinkProps {
  to: string
  pathToMatch?: string
  [key: string]: any
}

export const formatURI = (lang: string, to: string): string => {
  let link = `/${lang}${to}`
  if (to[0] !== '/') {
    link = `${to}`
  }
  return link
}

export const setActiveClassOnPathToMatch = (
  location: {
    [key: string]: any
  },
  pathToMatch: string,
  props: {
    [key: string]: any
  }
): {} => {
  const currentLocation = location.pathname
  if (currentLocation.includes(pathToMatch)) {
    return {
      className: `${props.className} ${props.activeClassName}`,
    }
  }
  return {}
}

const Link: React.FC<ILinkProps> = ({ to, pathToMatch, ...props }) => {
  const { lang } = usePageContext()
  const {
    children,
    activeClassName,
    partiallyActive,
    ...externalPropsToPass
  } = props

  const internalLinks = !/https?:\/\//.test(to) && !to.includes('mailto')

  const routeToLinkTo = (): string => {
    // lang is undefined for the default of english
    return lang ? formatURI(lang, to) : to
  }

  const renderGatsbyLink = (): JSX.Element => {
    if (pathToMatch) {
      return (
        <GatsbyLink
          {...props}
          to={routeToLinkTo()}
          getProps={({ location }: { [key: string]: any }): {} =>
            setActiveClassOnPathToMatch(location, pathToMatch, props)
          }
        />
      )
    }
    return <GatsbyLink {...props} to={routeToLinkTo()} />
  }

  return internalLinks ? (
    renderGatsbyLink()
  ) : (
    <a href={to} {...externalPropsToPass}>
      {children}
    </a>
  )
}

export default Link
