/*  eslint-disable @typescript-eslint/explicit-function-return-type*/
import React from 'react'
import { PageContextProvider } from './src/i18ncontext'
import LoadingLayout from './src/layouts/LoadingLayout'
import wrapWithProvider from './src/wrap-with-provider'

export const wrapRootElement = wrapWithProvider

export const wrapPageElement = ({ element, props }) => {
  const { docType = '', name = '', uuid = '' } = props.pageContext
  return (
    <PageContextProvider value={props.pageContext}>
      <LoadingLayout
        path={props.location.pathname}
        docType={docType}
        docName={name}
        uuid={uuid}
      >
        {element}
      </LoadingLayout>
    </PageContextProvider>
  )
}
