import { useEffect, useRef } from 'react'

export const useInterval = (callback: any, delay: number): void => {
  const savedCallback = useRef<any>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = (): void => {
      savedCallback.current()
    }

    if (delay !== null) {
      const intervalId = setInterval(tick, delay)
      return (): void => clearInterval(intervalId)
    }
  }, [delay])
}
