import './src/assets/styles/main.scss'
import wrapWithProvider from './src/wrap-with-provider'
import { escapeSpecialCharacters } from './src/utils/general.utils'
const scrollToAnchor = (location) => {
  let listenerAdded
  const smoothScrollToElement = () => {
    const parsedHash =
      location && location.hash && escapeSpecialCharacters(location.hash)
    const mainNavHeight = document.getElementsByClassName('header-wrapper')[0]
      .clientHeight
    const item = document.querySelector(`${parsedHash}`).offsetTop

    window.scrollTo({
      top: item - mainNavHeight,
      behavior: 'smooth',
    })
  }
  if (listenerAdded) {
    window.removeEventListener('load', smoothScrollToElement)
  }
  if (location && location.hash) {
    window.addEventListener('load', smoothScrollToElement)
    listenerAdded = true
  }
  return true
}
export const onRouteUpdate = ({ location }) => scrollToAnchor(location)
export const wrapRootElement = wrapWithProvider
export { wrapPageElement } from './gatsby-ssr'
