import { composeWithDevTools } from '@redux-devtools/extension'
import {
  Action,
  applyMiddleware,
  combineReducers,
  createStore,
  Store,
} from 'redux'
import thunk, { ThunkAction } from 'redux-thunk'
import { authReducer } from './auth'
import { tryOutReducer } from './tryOut'

export const reducer = combineReducers({
  auth: authReducer,
  expandTryOut: tryOutReducer,
})

export default (): Store<AppState> =>
  createStore(reducer, composeWithDevTools(applyMiddleware(thunk)))

export type AppState = ReturnType<typeof reducer>

export type AppThunk = ThunkAction<void, AppState, unknown, Action<string>>
