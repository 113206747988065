import { Reducer } from 'redux'
import { AppState } from '../store'

export enum TryOutActionTypes {
  TOGGLE_TRYOUT = 'TOGGLE_TRYOUT',
}

export const toggleTryout = (): any => {
  return {
    type: TryOutActionTypes.TOGGLE_TRYOUT,
  }
}

const initialState = false

export const tryOutReducer: Reducer<boolean> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case TryOutActionTypes.TOGGLE_TRYOUT: {
      return !state
    }
    default: {
      return state
    }
  }
}

export const getExpandTryOutState = (state: AppState): boolean =>
  state.expandTryOut
