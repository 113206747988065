import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { IContent } from '../interfaces/contentInterface'
import { IResource } from '../interfaces/resourceInterfaces'
import { retrievePermittedData } from '../state/auth'
import { AppState } from '../state/store'
import { makeKebabCase } from '../utils/general.utils'

interface INavData {
  name: string
  children: IChildNavItem[]
}

export interface IChildNavItem {
  name: string
  link: string
  isExternalLink: boolean
}

export const useNavData = (): INavData[] => {
  const { t } = useTranslation()
  const {
    guides,
    resources,
    overview,
    featurePages,
  } = useSelector((state: AppState) => retrievePermittedData(state))

  const createNavItems = (
    name: string,
    linkPrefix: string,
    isExternalLink = false
  ): IChildNavItem => {
    return {
      name,
      link: !isExternalLink
        ? `${linkPrefix}/${makeKebabCase(name)}`
        : linkPrefix,
      isExternalLink,
    }
  }

  return [
    {
      name: t('sideNav.overview'),
      children: [
        createNavItems(t('gettingStarted.gettingStarted'), '/documentation'),
        ...overview.map((overviewGuide: IContent) => {
          const { name } = overviewGuide
          return createNavItems(name, '/documentation')
        }),
        ...featurePages.map((featurePage: IContent) => {
          const { name } = featurePage
          return createNavItems(name, '')
        }),
      ],
    },
    {
      name: t('sideNav.apiReference'),
      children: [
        createNavItems(t('changelog.title'), '/documentation'),
        ...resources.map((resource: IResource) => {
          const { name } = resource
          return createNavItems(name, '/documentation')
        }),
      ],
    },
    {
      name: t('sideNav.guides'),
      children: guides.map((guide: IContent) => {
        const { name } = guide
        return createNavItems(name, '/documentation/guides')
      }),
    },
    {
      name: t('sideNav.help'),
      children: [
        createNavItems(t('sideNav.faq'), ''),
        createNavItems(
          t('sideNav.developerCommunity'),
          'https://community.cisco.com/t5/webex-for-developers/bd-p/disc-webex-developers',
          true
        ),
        createNavItems(
          t('sideNav.samples'),
          'https://github.com/CiscoDevNet/webex-contact-center-api-samples',
          true
        ),
      ],
    },
  ]
}
