import React from 'react'
import { useTranslation } from 'react-i18next'
import PageNotFoundIcon from '../assets/svgs/page-not-found.svg'
import ErrorTemplate from '../templates/errors'

const Page404 = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <ErrorTemplate title={t('notFoundPage.title')} icon={<PageNotFoundIcon />}>
      <>
        {t('notFoundPage.bodyIntro')}
        <br /> {t('notFoundPage.bodyConclusion')}
      </>
    </ErrorTemplate>
  )
}

export default Page404
